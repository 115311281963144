import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box, Typography, Icon } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";


import { formatDate, formatSecondsShort, formatDateShort, formatTimeWSuffix } from "../utils/dataFormatters";

import { useDispatch, useSelector } from "react-redux";
import { getMapData } from "../storage/mapdata";
import TimeList from "../components/map/TimeList";

import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckIcon from "@mui/icons-material/Check";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { ReactComponent as TierIcon } from "../icons/tieri.svg";
import { ReactComponent as CalendarIcon } from "../icons/kalenteri.svg";
import { ReactComponent as CalendarRedIcon } from "../icons/kalenteri_red.svg";
import { ReactComponent as PointsIcon } from "../icons/pisteet.svg";

const MapPage = () =>
{
	const { id } = useParams();

	const dispatch = useDispatch();
	const stats = useSelector(state =>
	{
		if ( state.mapdata[id] )
			return state.mapdata[id];

		return null;
	});
	
	useEffect(() =>
	{
		dispatch(getMapData(id));
	}, [id]);

	//console.log(stats);

	if ( stats && stats.error )
		return ( <div> {stats.msg} </div>);

	return (
		<div>
			{ stats && <Box p={0}>
				<Box sx={{
					display: "flex",
					flexWrap: "wrap",
					mb: 0,
				}}
				>
					<Typography 
						variant="h6"
						color="primary"
						//display="inline"
						mr={2}
						noWrap
					> 
						{id.toUpperCase()} STATS
					</Typography>

					<Box sx={{
						background: "#424242",
						display: "flex",
						flexWrap: "wrap",
						flexGrow: 1,
						justifyContent: "space-between",
						alignItems: "center",
						px: 2,
						columnGap: 1,
						minHeight: 32,
					}}>
						<Box sx={{ display: "flex", alignItems: "center", minHeight: 32 }}>
							<QueryBuilderIcon fontSize="small" sx={{ mb: 0.5 }}/>
							<Typography noWrap variant="subtitle2" >Played: {formatTimeWSuffix(stats.playtime)}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", minHeight: 32 }}>	
							<Icon fontSize="small" sx={{ mb: 1 }}>
								<CalendarIcon />
							</Icon>
							<Typography noWrap variant="subtitle2">Last Played: {formatDate(stats.lastPlayed)}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", minHeight: 32 }}>
							<Icon fontSize="small" sx={{ mb: 1 }}>
								<CalendarRedIcon />
							</Icon>
							<Typography noWrap variant="subtitle2" >Added: {formatDateShort(stats.added)}</Typography>
						</Box>
					</Box>
				</Box>
				<Box sx={{
					display: "flex",
					flexWrap: "wrap",
					mb: 1,
				}}
				>
					<Box sx={{
						background: "#424242",
						display: "flex",
						flexWrap: "wrap",
						flexGrow: 1,
						justifyContent: "space-between",
						alignItems: "center",
						px: 2,
						columnGap: 1,
						minHeight: 32,
						py: 0
					}}>
						<Box sx={{ display: "flex", alignItems: "center", minHeight: 32 }}>
							<Icon fontSize="small" sx={{ mb: 1 }}>
								<TierIcon />
							</Icon>
							<Typography noWrap variant="subtitle2">Tier: {stats.tier}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", minHeight: 32 }}>
							<SquareFootIcon sx={{ mb: 0.5, color: "gold" }} fontSize="small" />
							<Typography noWrap variant="subtitle2" >Length: {formatSecondsShort(stats.median_time)}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", minHeight: 32 }}>
							<CheckIcon sx={{ color: "#00FF00", mb: 0.5 }} fontSize="small" />
							<Typography noWrap variant="subtitle2" >Clears: {stats.completions || "-"}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", minHeight: 32 }}>
							<Icon fontSize="small" sx={{ mb: 1 }}>
								<PointsIcon />
							</Icon>
							<Typography noWrap variant="subtitle2" >Points: {stats.points.toFixed(2)}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", minHeight: 32 }}>
							<Icon fontSize="small" sx={{ mb: 1 }}>
								<PointsIcon />
							</Icon>
							<Typography noWrap variant="subtitle2" >Scroll: {stats.scroll.toFixed(2)}</Typography>
						</Box>
					</Box>
				</Box>
				<Grid container spacing={2}>
					<Grid xs={12}>
						<Typography variant="h6" color="primary">Times</Typography>
						<TimeList map={id}/>
					</Grid>
				</Grid>
			</Box>
			|| "Loading..."}
		</div>
	);
};

export default MapPage;