import axios from "axios";
import { backend_url } from "../utils/constants";
const baseUrl = backend_url + "times";

const getTimesWithParams = async(p) =>
{
	const params = new URLSearchParams();
	
	if ( p )
	{
		if ( p.map !== undefined ) params.append("map", p.map);
		if ( p.variant !== undefined ) params.append("var", p.variant);
		if ( p.type !== undefined ) params.append("t", p.type);
		if ( p.id !== undefined ) params.append("id", p.id);
	}

	const response = await axios.get(baseUrl, { params });

	return response.data;
};

const getPotato = async() =>
{
	const response = await axios.get(baseUrl + "/potato", );

	return response.data;
};

const exports =
{
	getTimesWithParams,
	getPotato
};

export default exports;