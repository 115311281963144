import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, Divider, Paper, Typography, useMediaQuery } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import HighlightedResult from "./HighlightedResult";

const HoverLink = styled(Link)(() => ({
	textDecoration: "none",
	color: "white",
	//userSelect: "none"
}));

const SearchResults = ( { data: { names, ids, maps }, loading, click } ) =>
{
	const isBig = useMediaQuery((theme) => theme.breakpoints.up("sm"));
	const [cursor, setCursor] = useState(-1);
	const navigate = useNavigate();

	const dataExists = () =>
	{
		return !( names && names.length === 0 
			&& ids && ids.length === 0 
			&& maps && maps.length === 0 
		);
	};

	const isDataError = () =>
	{
		return (names === null && ids === null && maps === null && !loading );
	};

	const playerData = (names || []).concat(ids || []).slice(0, 5);
	const mapData = (maps || []).slice(0, 5);
	const halfLen = playerData.length;
	const fullLen = playerData.length + mapData.length;

	if ( cursor > fullLen )
		setCursor(-1);

	const handleKeyDown = useCallback((e) => 
	{
		const { key } = e;
		//console.log(key, cursor, halfLen, fullLen);
		switch (key) 
		{
			case "ArrowUp":
				e.preventDefault();
				if ( cursor >= 0 )
					setCursor(cursor - 1);
				else if ( cursor === -1 )
					setCursor(fullLen - 1);
				break;
			case "ArrowDown":
				e.preventDefault();
				if ( cursor < fullLen-1 )
					setCursor(cursor + 1);
				else if ( cursor === fullLen-1 )
				{
					setCursor(-1);
				}
				break;
			case "Enter":
				if ( cursor !== -1 )
				{
					e.preventDefault();
					click();

					if ( cursor < halfLen)
						navigate(`/players/${playerData[cursor].item.id}`);
					else
						navigate(`/maps/${mapData[cursor-halfLen].item.map}`);
				}
				break;
			default:
				break;
		}
	}, [cursor, halfLen, fullLen]);

	useEffect(() => 
	{
		window.addEventListener("keydown", handleKeyDown);
	
		return () => 
		{
			window.removeEventListener("keydown", handleKeyDown);
		};

	}, [handleKeyDown]);

	const styling = isBig ? 
		{ 
			width: "100%",
			mt: 1,
			p: "2px",
			background: "#505050",
		} : 
		{ 
			background: "#505050",
			p: "2px",
			position: "absolute",
			zIndex: 9999,
			top: 46,
			left: 0,
			right: 0,
		};

	//console.log(maps, ids, names, loading);

	return (
		<Paper sx={ styling } onMouseDown={(e) => e.preventDefault()} elevation={6}>
			{ loading && (
				<>
					<Box sx={{ display: "flex", p: 1, alignItems: "center", background: "#303030"}}>
						<CircularProgress sx={{ ml: 1 }}/> 
						<Typography variant="body1" noWrap sx={{ ml: 1 }}>
							Loading...
						</Typography>
					</Box>
					<Divider/>
				</>
			)}
			{ (!dataExists() || isDataError()) && (
				<Box sx={{ background: "#393939" }}>
					<Typography variant="body2" noWrap sx={{ ml: 1, py: 0.5, color: "rgba(255, 255, 255, 0.5)" }}>
						No Results
					</Typography>
				</Box>
			)}
			{ playerData.length !== 0 && (
				<>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", py: 0.5, background: "#303030"}}>
						<Typography color="primary" sx={{ fontWeight: "bold" }}>
							Players
						</Typography>
					</Box>
					<Divider/>
				</>
			)}
			{ playerData.map((data, i) => (
				<HoverLink to={`/players/${data.item.id}`} key={data.refIndex}>
					<Box 
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							background: "#404040",
							//"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
							bgcolor: (theme) => cursor === i && alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
						}}
						onClick={click}
						onMouseEnter={() => setCursor(i)}
						onMouseLeave={() => setCursor(-1)}
					>
						<Typography
							variant="body2"
							noWrap
							sx={{
								ml: 1,
								py: 0.5
							}}
						>
							<HighlightedResult data={data} index="name"/>
						</Typography>
						<Typography
							variant="body2"
							noWrap
							sx={{
								mr: 1,
								py: 0.5
							}}
						>
							<HighlightedResult data={data} index="id"/>
						</Typography>
					</Box>
					{(mapData || playerData.length-1 !== i) && <Divider/>}
				</HoverLink>
			))}
			{ mapData.length !== 0 && (
				<>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", py: 0.5, background: "#303030"}}>
						<Typography color="primary" sx={{ fontWeight: "bold" }}>
							Maps
						</Typography>
					</Box>
					<Divider/>
				</>
			)}
			{ mapData.map((data, i) => (
				<HoverLink to={`/maps/${data.item.map}`} key={data.refIndex}>
					<Box 
						sx={{
							//"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
							background: "#404040",
							bgcolor: (theme) => cursor === halfLen+i && alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
						}}
						onClick={click}
						onMouseEnter={() => setCursor(halfLen+i)}
						onMouseLeave={() => setCursor(-1)}
					>
						<Typography
							variant="body2"
							noWrap
							sx={{
								ml: 1,
								py: 0.5
							}}
						>
							<HighlightedResult data={data} index="map"/>
						</Typography>
					</Box>
					{mapData.length-1 !== i && <Divider/>}
				</HoverLink>
			))}
		</Paper>
	);
};

export default SearchResults;