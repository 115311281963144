import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box, Typography, Paper, Avatar } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";


import { formatTimeWSuffix, formatDate } from "../utils/dataFormatters";
import PlayerTabs from "../components/player/Tabs";
import Awards from "../components/player/Awards";
import Completion from "../components/player/Completion";
import Ranks from "../components/player/Ranks";
import ACRS from "../components/player/ACR";
import NearRanks from "../components/player/NearRanks";
import Stats from "../components/player/Stats";
import MapList from "../components/player/MapList";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerFull } from "../storage/players";
import RecentsList from "../components/player/RecentsList";

const PlayerPage = () =>
{
	const { id, tab } = useParams();

	const stats = useSelector(state => state.players[id]);
	const dispatch = useDispatch();

	useEffect(() =>
	{
		dispatch(getPlayerFull(id));
	}, [id]);

	if ( stats && stats.error )
		return ( <div> {stats.msg} </div>);

	// eslint-disable-next-line no-console
	//console.log(stats);

	return (
		<div>
			{ stats && <Box p={0}>
				<Box sx={{
					display: "flex",
					flexWrap: "wrap",
				}}
				>
					<Paper square elevation={0} sx={{ 
						flexGrow: 1,
						display: "flex",
						flexWrap: "wrap",
						padding: 1,
						//background: "#212121"
					}}>
						<Avatar
							variant="square"
							alt="?"
							src={stats.steam.avatarfull}
							sx={{ width: 64, height: 64, marginRight: 1 }}
							component="a"
							href={stats.steam.profileurl}
							target='_blank'
							rel="noopener noreferrer"
						/>
						<Box sx = {{ flexGrow: 1 }}>
							<Typography 
								variant="h6"
								color="primary"
								mr={2}
								sx={{ textDecoration: "none" }}
								noWrap
								component="a"
								href={stats.steam.profileurl}
								target='_blank'
								rel="noopener noreferrer"
							> 
								{ stats.steam.personaname }
							</Typography>
							<Typography 
								variant="body1"
								mr={2}
								noWrap
							> 
								{ stats.rankName } {` (${stats.rank} / ${stats.totalRanks})` }
							</Typography>
						</Box>
						<Box sx = {{ mt: 1, display: "flex", flexWrap: "wrap", alignItems: "flex-end"}}>
							<Typography noWrap variant="caption" pr={2} >Playtime: {formatTimeWSuffix(stats.playtime)}</Typography>
							<Typography noWrap variant="caption" pr={2} >Servertime: {formatTimeWSuffix(stats.servertime)}</Typography>
							<Typography noWrap variant="caption" pr={2} >Last Seen: {formatDate(stats.lastConnect)}</Typography>
						</Box>
					</Paper>
				</Box>
				<PlayerTabs tab={tab}>
					<div>
						<Grid container spacing={2} display={{ xs: "flex", md: "none" }}>
							<Grid xs={12} md={6}>
								<Typography pl={2} mb={1} color="primary">Awards</Typography>
								<Awards awards={stats.runs}/>
							</Grid>
							<Grid xs={12} md={6}>
								<Typography pl={2} mb={1} color="primary">Completion</Typography>
								<Completion runs={stats.runs} zones={stats.zones}/>
							</Grid>
							<Grid xs={12} md={6}>
								<Typography pl={2} mb={1} color="primary">Ranks</Typography>
								<Ranks runs={stats.runs}/>
							</Grid>
							<Grid xs={12} md={6}>
								<Typography pl={2} mb={1} color="primary">Nearby Ranks</Typography>
								<NearRanks nearRanks={stats.nearRanks} points={stats.points} total={stats.totalRanks}/>
							</Grid>
							<Grid xs={12} md={6}>
								<Typography pl={2} mb={1} color="primary">Summary</Typography>
								<Stats stats={stats}/>
							</Grid>
						</Grid>
						<Grid container spacing={2} display={{ xs: "none", md: "flex" }}>
							<Grid xs={12}>
								<Typography pl={2} mb={1} color="primary">Awards / Completion / Ranks</Typography>
								<ACRS runs={stats.runs} zones={stats.zones}/>
							</Grid>
							<Grid xs={12} md={6}>
								<Typography pl={2} mb={1} color="primary">Nearby Ranks</Typography>
								<NearRanks nearRanks={stats.nearRanks} points={stats.points} total={stats.totalRanks}/>
							</Grid>
							<Grid xs={12} md={6}>
								<Typography pl={2} mb={1} color="primary">Summary</Typography>
								<Stats stats={stats}/>
							</Grid>
						</Grid>
					</div>

					<Grid container spacing={2} justifyContent="center">
						<Grid xs={12}>
							<MapList id={id} />
						</Grid>
					</Grid>

					<Grid container spacing={2} justifyContent="center">
						<Grid xs={12}>
							<RecentsList id={id} />
						</Grid>
					</Grid>
				</PlayerTabs>
			</Box>
			|| "Loading..."}
		</div>
	);
};

export default PlayerPage;