import { Container } from "@mui/material";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TopBar from "./components/TopBar";

import { pages } from "./pages";


const App = () =>
{
	return (
		<Router>
			<Container>
				<TopBar />
				<Routes>
					{
						pages.map( (page, index) => (
							<Route key={index} path={page.path} element={page.element} />
						))
					}
				</Routes>
			</Container>
				
		</Router>

	);
};

export default App;