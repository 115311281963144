import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

const RankBlock = ({stats, points, total, lText, rText, eText}) =>
{
	if ( stats.id === 0 )
	{
		return (
			<Box sx = {{ 
				flexGrow: 1,
				background: "#424242",
				padding: 1,
				display: "flex"
			}}>
				<Box sx={{
					//background: "#212121",
					flexGrow: 1,
				}}>
					<Typography 
						variant="body2"
						color="primary"
						ml={1}
						noWrap
					>
						{eText}
					</Typography>
				</Box>
				<Box sx={{
					justifyContent: "flex-end",
					alignItems: "flex-end",
				}}>
					<Typography 
						variant="body2"
						color="primary"
						ml={1}
						mr={1}
						noWrap
					>
						{lText}
					</Typography>
				</Box>
			</Box>);
	}
	return (
		<Box sx = {{ 
			flexGrow: 1,
			background: "#424242",
			px: 1,
			py: 0.5,
			display: "flex",
			flexWrap: "wrap"
		}}>
			<Box sx={{
				//background: "#212121",
				flexGrow: 1,
			}}>

				<Typography 
					variant="h6"
					color="primary"
					ml={1}
					noWrap
					component={Link}
					to={`/players/${stats.id}`}
					sx={{ textDecoration: "none" }}
				> 
					{ stats.name }
				</Typography>
				<Typography 
					variant="body2"
					ml={1}
					noWrap
				> 
					{/* Rank: { stats.rank } ({ (stats.rank * 100 / total).toFixed(3) }%) */}
					{`${stats.rankName} - ${stats.rank} (${(stats.rank * 100 / total).toFixed(3)}%)`}
				</Typography>
			</Box>
			<Box sx={{
				//background: "#212121",
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-end",
				alignItems: "flex-end",
				flexGrow: 1,
			}}>
				<Typography 
					variant="body2"
					color="primary"
					noWrap
					//mr={1}
				>
					{lText}
				</Typography>
				<Typography 
					variant="body2"
					noWrap
					//mr={1}
				> 
					{rText} { Math.abs(stats.points - points).toFixed(2) }
				</Typography>
				<Typography 
					variant="body2"
					noWrap
					//mr={1}
				> 
					Points: { stats.points.toFixed(2) }
				</Typography>
			</Box>
		</Box>
	);
};

const NearRanks = ({nearRanks, points, total}) =>
{
	//const style = { paddingLeft: 1, color: "rgba(255, 255, 255, 0.8)" };

	return (
		<Paper square elevation={0} sx={{ 
			flexGrow: 1,
			display: "flex",
			flexWrap: "wrap",
			flexDirection: "column",
			gap: "2px",
			padding: "2px",
			background: "#212121"
		}}>

			<RankBlock 
				stats={nearRanks.next}
				points={points}
				total={total}
				lText="Next Rank Group"
				rText="To reach:"
				eText="Top rank group reached"
			/>
			<RankBlock 
				stats={nearRanks.immediateNext}
				points={points}
				total={total}
				lText="Next Rank"
				rText="To reach:"
				eText="Top rank reached"
			/>
			<RankBlock 
				stats={nearRanks.immediatePrevious}
				points={points}
				total={total}
				lText="Previous Rank"
				rText="Ahead by:"
				eText="Lowest rank reached"
			/>
			<RankBlock 
				stats={nearRanks.previous}
				points={points}
				total={total}
				lText="Previous Rank Group"
				rText="Ahead by:"
				eText="Lowest rank group reached"
			/>
		</Paper>
	);
};

export default NearRanks;