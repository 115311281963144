import React from "react";
import { Box, CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import HighlightedResult from "./HighlightedResult";

const HoverLink = styled(Link)(() => ({
	textDecoration: "none",
	color: "white",
	//userSelect: "none"
}));

const SearchResultsFull = ( { data: { names, ids, maps }, loading } ) =>
{
	const dataExists = () =>
	{
		return !( names && names.length === 0 
			&& ids && ids.length === 0 
			&& maps && maps.length === 0 
		);
	};

	const playerData = (names || []).concat(ids || []);
	const mapData = (maps || []);

	return (
		<Box sx={{ mt: 1 }} >
			{(loading || !dataExists()) && (
				<Paper sx={{ mt: 1, background: "#505050", p: "2px" }} elevation={0}>
					{ loading && (
						<>
							<Box sx={{ display: "flex", p: 1, alignItems: "center", background: "#303030"}}>
								<CircularProgress sx={{ ml: 1 }}/> 
								<Typography variant="body1" noWrap sx={{ ml: 1 }}>
									Loading...
								</Typography>
							</Box>
							<Divider/>
						</>
					)}
					{ !dataExists() && (
						<Box sx={{ background: "#393939" }}>
							<Typography variant="body2" noWrap sx={{ ml: 1, py: 0.5, color: "rgba(255, 255, 255, 0.5)" }}>
							No Results
							</Typography>
						</Box>
					)}
				</Paper>
			)}
			{playerData.length !== 0 && (
				<Paper sx={{ mt: 1, background: "#505050", p: "2px" }} elevation={0}>
					{ playerData.length !== 0 && (
						<>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", py: 0.5, background: "#303030" }}>
								<Typography color="primary" sx={{ fontWeight: "bold" }}>
									Players
								</Typography>
							</Box>
							<Divider/>
						</>
					)}
					{ playerData.map((data, i) => (
						<HoverLink to={`/players/${data.item.id}`} key={data.refIndex}>
							<Box 
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									background: "#424242",
									"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
								}}

							>
								<Typography
									variant="body2"
									noWrap
									sx={{
										ml: 1,
										py: 0.5
									}}
								>
									<HighlightedResult data={data} index="name"/>
								</Typography>
								<Typography
									variant="body2"
									noWrap
									sx={{
										mr: 1,
										py: 0.5
									}}
								>
									<HighlightedResult data={data} index="id"/>
								</Typography>
							</Box>
							{playerData.length-1 !== i && <Divider/>}
						</HoverLink>
					))}
				</Paper>
			)}
			{mapData.length !== 0 && (
				<Paper sx={{ mt: 1, background: "#505050", p: "2px" }} elevation={0}>
					{ mapData.length !== 0 && (
						<>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", py: 0.5, background: "#303030" }}>
								<Typography color="primary" sx={{ fontWeight: "bold" }}>
									Maps
								</Typography>
							</Box>
							<Divider/>
						</>
					)}
					{ mapData.map((data, i) => (
						<HoverLink to={`/maps/${data.item.map}`} key={data.refIndex}>
							<Box 
								sx={{
									background: "#424242",
									"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
								}}
							>
								<Typography
									variant="body2"
									noWrap
									sx={{
										ml: 1,
										py: 0.5
									}}
								>
									<HighlightedResult data={data} index="map"/>
								</Typography>
							</Box>
							{mapData.length-1 !== i && <Divider/>}
						</HoverLink>
					))}
				</Paper>
			)}
		</Box>
	);
};

export default SearchResultsFull;