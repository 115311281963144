import React from "react";

import MainPage from "./Pages/MainPage";
import MapPage from "./Pages/MapPage";
import MapsPage from "./Pages/MapsPage";
import PlayerPage from "./Pages/PlayerPage";
import PlayersPage from "./Pages/PlayersPage";
import RecentsPage from "./Pages/RecentsPage";
import SearchPage from "./Pages/SearchPage";

export const pages =
[
	{
		element: <MainPage />,
		path: "/"
	},
	{
		element: <SearchPage />,
		path: "/search"
	},
	{
		element: <MapsPage />,
		path: "/maps"
	},
	{
		element: <MapPage />,
		path: "/maps/:id"
	},
	{
		element: <PlayersPage />,
		path: "/players"
	},
	{
		element: <PlayerPage />,
		path: "/players/:id"
	},
	{
		element: <RecentsPage />,
		path: "/recents"
	},
	{
		element: <div> Page not found </div>,
		path: "*"
	}
];

//use this instead of spaces for divider text
//const nbsp = "\xa0";

export const displayedPages =
[
	{
		name: "Maps",
		path: "/maps"
	},
	{
		name: "Top 100",
		path: "/players"
	},
	{
		name: "Recents",
		path: "/recents"
	}
];

export default { pages, displayedPages };