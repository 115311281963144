import { createSlice } from "@reduxjs/toolkit";

import mapsService from "../services/maps";
import { stripPrefix } from "../utils/dataFormatters";

const mapdataSlice = createSlice({
	name: "mapdata",
	initialState: {},
	reducers:
	{
		addMapWTimes(state, action)
		{
			const id = action.payload.id;
			const times = action.payload.times;
			const maps = state;
			maps[id] = times;

			return maps;
		},
		addMapList(state, action)
		{
			const maplist = action.payload;
			const maps = state;
			maps.maplist = maplist;

			return maps;
		},
		addMapData(state, action)
		{
			const map = action.payload.map;
			const data = action.payload.data;
			const maps = state;
			maps[map] = data;

			return maps;
		},
		deleteDataFromIndex(state, action)
		{
			const index = action.payload.index;

			const curState = state;
			delete curState[index];

			return curState;
		}
	}
});

export const { 
	addMapWTimes,
	addMapList,
	addMapData,
	deleteDataFromIndex
} = mapdataSlice.actions;

export default mapdataSlice.reducer;

export const getMapData = ( map ) =>
{
	return async (dispatch, getState) =>
	{
		if ( getState().mapdata[map] )
			return;

		try
		{
			const data = await mapsService.getSingleStats(map);
			dispatch(addMapData( { map, data }));
		}
		catch ( e )
		{
			try
			{
				if ( e.response.status === 404 )
					dispatch(addMapData( { data: { error: true, msg: "not found"}, map }));
				else
					dispatch(addMapData( { data: { error: true, msg: "error"}, map }));
			}
			catch ( e2 )
			{
				dispatch(addMapData( { data: { error: true, msg: "error"}, map }));
			}
		}
	};
};

export const getMapList = () =>
{
	return async (dispatch, getState) =>
	{
		if ( getState().mapdata.maplist )
			return;

		try
		{
			dispatch(addMapList({ loading: true }));

			const maplist = await mapsService.getAllMaps();

			maplist.map(row =>
			{
				row.mapNoPrefix = stripPrefix(row.name);
			});
					
			dispatch(addMapList(maplist));
		}
		catch ( e )
		{
			dispatch(deleteDataFromIndex({ index: "maplist" }));
			//console.log(e);
		}
	};
};