import React from "react";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";

function TabPanel(props) 
{
	const { children, value, index, ...other } = props;
  
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`player-tab-${index}`}
			aria-labelledby={`player-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

const tabNameToIndex = (tab) =>
{
	switch(tab)
	{
		case "maps":
			return 1;
		case "recents":
			return 2;
		default:
			return 0;
	}
};

const tabIndexToName = (tab) =>
{
	switch(tab)
	{
		case 1:
			return "maps";
		case 2:
			return "recents";
		default:
			return "stats";
	}
};

const PlayerTabs = ({ children }) => 
{
	const [searchParams, setSearchParams] = useSearchParams();
	const tabIndex = tabNameToIndex(searchParams.get("tab"));

	const handleChange = (event, newTabIndex) => 
	{
		searchParams.set("tab", tabIndexToName(newTabIndex));
		setSearchParams(searchParams);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<AppBar position="static" sx={{ mb: 2 }}>
				<Tabs value={tabIndex} onChange={handleChange} >
					<Tab label="Stats" />
					<Tab label="Maps left/done" />
					<Tab label="Recents" />
				</Tabs>
			</AppBar>
			<TabPanel value={tabIndex} index={0}>
				{children[0]}
			</TabPanel>
			<TabPanel value={tabIndex} index={1}>
				{children[1]}
			</TabPanel>
			<TabPanel value={tabIndex} index={2}>
				{children[2]}
			</TabPanel>
		</Box>
	);
};

export default PlayerTabs;