export const formatDate = (timestamp) =>
{
	return new Date(timestamp * 1000).toLocaleString();
};

export const formatDateShort = (timestamp) =>
{
	return new Date(timestamp * 1000).toLocaleDateString();
};

export const formatTime = (input) =>
{
	let time = Number(input) || 0;

	const hours = Math.floor(time / 3600).toString().padStart(2, "0");
	time -= hours * 3600;

	const minutes = Math.floor(time / 60).toString().padStart(2, "0");
	time -= minutes * 60;

	const seconds = Math.floor(time).toString().padStart(2, "0");

	return `${hours}:${minutes}:${seconds}`;
};

export const formatTimeWSuffix = (input) =>
{
	let time = Number(input) || 0;

	// if ( time === 0 )
	// 	return "-";

	const hours = Math.floor(time / 3600).toString().padStart(2, "0");
	time -= hours * 3600;

	const minutes = Math.floor(time / 60).toString().padStart(2, "0");
	time -= minutes * 60;

	//const seconds = Math.floor(time).toString().padStart(2, "0");

	return `${hours}h ${minutes}m`;
};

export const formatSeconds = (input) =>
{
	let time = Number(input) || 0;

	const hours = Math.floor(time / 3600).toString();
	time -= hours * 3600;

	const minutes = Math.floor(time / 60).toString().padStart(2, "0");
	time -= minutes * 60;

	let seconds = minutes < 1 ? time.toFixed(6).padStart(9, "0") : time.toFixed(3).padStart(6, "0");

	if ( hours > 0 )
		return `${hours}:${minutes}:${seconds}`;

	return `${minutes}:${seconds}`;
};

export const formatSecondsShort = (input) =>
{
	if ( !input )
		return "-";

	let time = Number(input) || 0;

	const hours = Math.floor(time / 3600).toString();
	time -= hours * 3600;

	let minutes = Math.floor(time / 60).toString();//.padStart(2, "0");
	time -= minutes * 60;

	let seconds = time.toFixed(0).padStart(2, "0");

	if ( hours > 0 )
	{
		minutes = minutes.padStart(2, "0");
		return `${hours}:${minutes}:${seconds}`;
	}

	return `${minutes}:${seconds}`;
};

export const stripPrefix = (name) =>
{
	if ( name.startsWith("bhop_") )
		return name.substring(5);

	if ( name.startsWith("kz_bhop_"))
		return name.substring(7);

	return name;
};

export const addPrefix = (name) =>
{
	if ( !name.startsWith("_") )
		return "bhop_" + name;

	if ( name.startsWith("_"))
		return "kz_bhop" + name;

	return name;
};

const exports =
{
	formatDate,
	formatDateShort,
	formatTime,
	formatTimeWSuffix,
	formatSeconds,
	formatSecondsShort,
	stripPrefix,
	addPrefix,
};

export default exports;