import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Icon } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { ReactComponent as VariantIcon } from "../../icons/variantti.svg";

const Completion = ({runs, zones}) =>
{
	const getTotal = (type) =>
	{
		switch ( type )
		{
			case 0: return zones.normal;
			case 1: return zones.bonus;
			case 2: return zones.extra;
			default: return 0;
		}
	};

	const style = { paddingLeft: 1, color: "rgba(255, 255, 255, 0.8)" };

	return (
		<TableContainer component={Paper} square >
			<Table size="small" padding="none">
				<TableHead>
					{ runs.length === 0 ? (
						<TableRow>
							<TableCell align="center">No Times Found</TableCell>
						</TableRow>
					):(
						<TableRow>
							<TableCell align="left" sx={{ paddingLeft: 1}}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
									<Typography variant="inherit">Variant</Typography>
									<Icon sx={{ alignSelf: "flex-start" }} fontSize="small">
										<VariantIcon />
									</Icon>
								</Box>
							</TableCell>
							<TableCell align="center" sx={{ paddingLeft: 1}}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<Typography variant="inherit">Clears / Total (Percent)</Typography>
									<CheckIcon sx={{ color: "#00FF00", alignSelf: "flex-start"}} fontSize="small" />
								</Box>
							</TableCell>
						</TableRow>
					)}
				</TableHead>
				<TableBody>
					{runs.map((row, index) => (
						<TableRow
							key={index}
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
						>
							<TableCell component="th" scope="row" align="left" sx={style}>
								{row.name}
							</TableCell>
							<TableCell align="center" sx={style}>
								{`${row.clears} / ${getTotal(row.type)} (${(row.clears / getTotal(row.type) * 100).toFixed(1)}%)`}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default Completion;