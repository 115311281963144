import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Icon } from "@mui/material";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import { ReactComponent as VariantIcon } from "../../icons/variantti.svg";

const Awards = ({awards}) =>
{
	const style = { paddingLeft: 1, color: "rgba(255, 255, 255, 0.8)" };
	return (
		<TableContainer component={Paper} square >
			<Table size="small" padding="none">
				<TableHead>
					{ awards.length === 0 ? (
						<TableRow>
							<TableCell align="center">No Awards Found</TableCell>
						</TableRow>
					):(
						<TableRow>
							<TableCell align="left" sx={{ paddingLeft: 1}}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
									<Typography variant="inherit">Variant</Typography>
									<Icon sx={{ alignSelf: "flex-start" }} fontSize="small">
										<VariantIcon />
									</Icon>
								</Box>
							</TableCell>
							<TableCell align="left" sx={{ paddingLeft: 1}}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
									<Typography variant="body2" sx={{ fontWeight: "bold" }}>#1</Typography>
									<TrophyIcon sx={{ color: "#FFD700", alignSelf: "flex-start" }} fontSize="small" />
								</Box>
							</TableCell>
							<TableCell align="left" sx={{ paddingLeft: 1}}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
									<Typography variant="body2" sx={{ fontWeight: "bold" }}>#2</Typography>
									<TrophyIcon sx={{ color: "#C0C0C0", alignSelf: "flex-start" }} fontSize="small" />
								</Box>
							</TableCell>
							<TableCell align="left" sx={{ paddingLeft: 1}}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
									<Typography variant="body2" sx={{ fontWeight: "bold" }}>#3</Typography>
									<TrophyIcon sx={{ color: "#CD7F32", alignSelf: "flex-start" }} fontSize="small" />
								</Box>
							</TableCell>
						</TableRow>
					)}
				</TableHead>
				<TableBody>
					{awards.map((row, index) => (
						<TableRow
							key={index}
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
						>
							<TableCell component="th" scope="row" align="left" sx={style}>
								{row.name}
							</TableCell>
							<TableCell align="left" sx={style}>
								{row.awards[0]}
							</TableCell>
							<TableCell align="left" sx={style}>
								{row.awards[1]}
							</TableCell>
							<TableCell align="left" sx={style}>
								{row.awards[2]}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default Awards;