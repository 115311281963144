import React from "react";
import { AppBar, Toolbar, Box, IconButton, Typography, Button, Menu, MenuItem, Divider, Icon} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Container } from "@mui/system";
import { Link, useLocation } from "react-router-dom";

import { displayedPages } from "../pages";
import SearchBar from "./search/SearchBar";
import { ReactComponent as DBIcon } from "../icons/db.svg";

const TopBar = () =>
{
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	
	const handleOpenNavMenu = (event) =>
	{
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () =>
	{
		setAnchorElNav(null);
	};

	const location = useLocation();
	const isMainPage = location.pathname === "/";
	//console.log(isMainPage);

	return (
		<>
			<AppBar position="fixed"
				//elevation={0}
			>
				<Container maxWidth="xl">
					<Toolbar disableGutters variant="dense"
						sx={{ width: "100%", maxWidth: 1152, mx: "auto" }}
					>
						{/* Left Logo icon + app name + link to main page */}
						{/* <StarIcon sx={{ mr: 1}} color="primary" /> */}
						<Box 
							sx={{ 
								display: "flex", 
								alignItems: "center",
								textDecoration: "none",
							}}
							component={Link}
							to="/"
						>
							<Icon color="primary">
								<DBIcon />
							</Icon>
							<Typography
								variant="h5"
								noWrap
								sx={{
									ml: 0.5,
									mr: 2,
									fontFamily: "monospace",
									fontWeight: 700,
									letterSpacing: ".1rem",
									overflow: "visible",
								}}
								color="primary"
								
							>
							FH-DB
							</Typography>
						</Box>

						{ !isMainPage && <SearchBar /> }

						{/* empty item to move everything to right */}
						<Box sx={{ flexGrow: 1 }}/>
	
						{/* page buttons when on larger screen */}
						<Box sx={{ flexGrow: 0, display: { xs: "none", sm: "flex" } }}>
							{ displayedPages.map((page, index) => (
								page.divider ? (
									// very hacky solution for topbar divider
									<Typography
										key={index}
										type="title"
										color="text.secondary"
										variant="caption"
										style={{ 
											borderRight: "0.1em solid rgba(255, 255, 255, 0.12)",
											textAlign: "bottom",
											width: 0,
											paddingTop: 10,
											height: 60,
											overflow: "visible",
											fontSize: "0.65rem"
										}}
									>
										&nbsp;&nbsp;&nbsp;{page.text}
									</Typography>

								):(
									<Button
										variant="text"
										key={index}
										//onClick={() => onPageItemClick(page)}
										sx={{ 
											my: 2,
											ml: 2,
											//display: "block"
											//justifyContent: "flex-end"
											minWidth: 0,
										}}
										color="primary"
										component={Link}
										to={page.path}
									>
										<Typography variant="inherit" noWrap>
											{page.name}
										</Typography>
									</Button>
								)
							))}
						</Box>

						{/* page buttons under a menu button on the right when on small screen */}
						<Box sx={{ flexGrow: 0, display: { xs: "flex", sm: "none" } }}>
							<IconButton
								size="large"
								aria-label="page menu"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="primary"
							>
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", sm: "none" },
								}}
								//PaperProps={{sx: {minWidth: "8em"}}}
							>
								{ displayedPages.map((page, index) => (
									page.divider ? (
										<div key={index}>
											<li>
												<Typography
													sx={{ mt: 1, ml: 2 }}
													color="text.secondary"
													display="block"
													variant="caption"
												>
													{page.text}
												</Typography>
											</li>
											<Divider component="li" />
										</div>
									):(
										<MenuItem 
											key={index}
											//onClick={() => onPageItemClick(page)}
											component={Link}
											to={page.path}
											onClick={handleCloseNavMenu}
										>
											<Typography textAlign="center">
												{page.name}
											</Typography>
										</MenuItem>
									)
								))}
							</Menu>
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
			<Toolbar sx={{ mt: 2 }} variant="dense"/>
		</>
	);
};

export default TopBar;