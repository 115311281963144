import React, { useEffect } from "react";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SearchBar from "../components/search/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { getServerPotato } from "../storage/times";

const MainPage = () =>
{
	const potato = useSelector(state => state.times.potato);
	const dispatch = useDispatch();

	useEffect(() =>
	{
		dispatch(getServerPotato());
	}, []);

	const getDisplay = () =>
	{
		if ( !potato )
			return ( <div> No data </div> );
		else if ( potato.loading )
			return ( <div> Loading... </div> );
		
		const subs = potato[3].sub.slice().sort((a, b) => a.variantValue < b.variantValue ? -1 : 1);
		return (
			<>
				<Typography variant="h6">{potato[0].count} Maps</Typography>
				<Typography sx={{ fontSize: "1.1rem"}}>{potato[1].count} Players ({potato[2].count} Ranked)</Typography>
				<Typography>{potato[3].count} Times</Typography>
				{subs.map((row, i) => (
					<Typography key={i} variant="caption" sx={{ fontSize: "11px"}}>
						{row.count} {row.variant}
					</Typography>
				))}
				<SearchBar/>
			</>
		);
	};

	return (
		<Box p={0}>
			<Grid container spacing={2} justifyContent="center">
				<Grid xs={12} md={6}>
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
						{getDisplay()}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default MainPage;