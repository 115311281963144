import React, { useEffect } from "react";

import {
	Box, Table, TableBody, TableCell, TableContainer,
	TableHead, TableRow, Button, Menu, MenuItem, TextField,
	Toolbar, Typography, Paper, IconButton, Icon
} from "@mui/material";

import { alpha, styled } from "@mui/material/styles";

import { formatDate, formatDateShort, formatSecondsShort, formatTimeWSuffix } from "../utils/dataFormatters";

//icons
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMapList } from "../storage/mapdata";

import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckIcon from "@mui/icons-material/Check";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import TerrainIcon from "@mui/icons-material/Terrain";
import { ReactComponent as TierIcon } from "../icons/tieri.svg";
import { ReactComponent as CalendarIcon } from "../icons/kalenteri.svg";
import { ReactComponent as CalendarRedIcon } from "../icons/kalenteri_red.svg";

const HoverLink = styled(Link)(() => ({
	textDecoration: "none",
	color: "white"
}));


function descendingComparator(a, b, orderBy) 
{
	if ( !a[orderBy] )
		return -1;

	if ( !b[orderBy] )
		return 1;

	if (b[orderBy] < a[orderBy]) 
		return -1;

	if (b[orderBy] > a[orderBy]) 
		return 1;

	return 0;
}

function ascendingComparator(a, b, orderBy) 
{
	if ( !b[orderBy] || b[orderBy] === "-" )
		return 1;

	if ( !a[orderBy] || a[orderBy] === "-" )
		return -1;

	if (b[orderBy] < a[orderBy]) 
		return 1;

	if (b[orderBy] > a[orderBy]) 
		return -1;

	return 0;
}

function getNComparator(order, orderBy) 
{
	return order === "desc"
		? (a, b) => ascendingComparator(a, b, orderBy)
		: (a, b) => -ascendingComparator(a, b, orderBy);
}


function getComparator(order, orderBy) 
{
	if ( ["completions", "playtime", "lastPlayed", "added"].includes(orderBy) )
		return getNComparator(order, orderBy);

	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) 
{
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => 
	{
		const order = comparator(a[0], b[0]);
		if (order !== 0) 
		{
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const PageSelectButton = ( { defText, text, buttons = [], setFunc, ...rest } ) =>
{
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [buttonText, setButtonText] = React.useState(text);

	const open = Boolean(anchorEl);

	const handleClick = (event) => 
	{
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (buttonText) => 
	{
		if ( buttonText )
		{
			setFunc(buttonText);
			setButtonText(buttonText);
		}

		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				id={"table-button" + defText }
				aria-controls={open ? "table-menu" + defText : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				variant="text"
				disableElevation
				disableRipple
				endIcon={<ArrowDropDownIcon sx={{ color: "#1e1e1e"}}/>}
				size="small"
				sx={{ minWidth: 0, textTransform: "none" }}
				color="white"
				{...rest}
			>
				<Typography variant="caption">
					{ buttonText ? buttonText : defText }
				</Typography>

			</Button>
			<Menu
				id={ "table-menu" + defText }
				MenuListProps={{ "aria-labelledby": "table-button" + defText }}
				anchorEl={anchorEl}
				open={open}
				onClose={() => handleClose()}
				transitionDuration={0}
			>
				{buttons.map((txt, index) => (
					<MenuItem dense
						key={index}
						onClick={() => handleClose(txt)}
						disableRipple
					>
						{txt}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

const DataTableHeader = ({ filter, setPage })  =>
{
	const [f, setF] = filter;

	const handleClearClick = () =>
	{
		setF("");
	};

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 2 },
				display: "flex",
				flexWrap: "wrap",
			}}
		>
			<Box sx={{
				display: "flex",
				my: 2,
				mr: 2,
				flexGrow: 1,
			}}
			>
				<Typography
					sx={{ flex: "1 1 100%" }}
					variant="h6"
					id="tableTitle"
					component="div"
				>
					Map List
				</Typography>
			</Box>
			
			<Box sx={{
				display: "flex",
				my: 1
			}}>
				<TextField
					hiddenLabel
					id="filled-hidden-label-small"
					variant="outlined"
					size="small"
					label="Filter maps"
					value={f}
					autoComplete="off"
					onChange={(e) =>
					{
						setF(e.target.value);
						setPage(0);
					}}
					InputProps={{
						endAdornment: (
							<IconButton
								onClick={handleClearClick}
								sx={{ visibility: f ? "visible" : "hidden"}}
							>
								<ClearIcon />
							</IconButton>

						)
					}}
				/>
			</Box>
		</Toolbar>
	);
};

const DataTableFooter = ({ pageA, rowsPerPageA, rowCount }) =>
{
	const [page, setPage] = pageA;
	const [rowsPerPage, setRowsPerPage] = rowsPerPageA;

	const handlePageChange = (buttonType) =>
	{
		switch(buttonType)
		{
			case "first":
				setPage(0);
				break;
			case "left":
				setPage(page - 1);
				break;
			case "right":
				setPage(page + 1);
				break;
			case "last":
				setPage(Math.max(0, Math.ceil(rowCount / rowsPerPage) - 1));
				break;
		}
	};

	const handleRowChange = (value) =>
	{
		if ( value === "All" )
			value = rowCount;

		setRowsPerPage(value);
		setPage(0);
	};

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 2 },
				py: 0,
				display: "flex",
				flexWrap: "wrap",
			}}
		>
			<Box sx={{
				display: "flex",
				//my: 2,
				mr: 2,
				flexGrow: 1,
			}}
			>
				<IconButton
					onClick={() => handlePageChange("first")}
					disabled={page === 0}
					aria-label="first page"
				>
					<FirstPageIcon sx={{ color: page === 0 ? "#313131" : "#fff"}}/>
				</IconButton>
				<IconButton
					onClick={() => handlePageChange("left")}
					disabled={page === 0}
					aria-label="previous page"
				>
					<KeyboardArrowLeft sx={{ color: page === 0 ? "#313131" : "#fff"}}/>
				</IconButton>
				<IconButton
					onClick={() => handlePageChange("right")}
					disabled={page >= Math.ceil(rowCount / rowsPerPage) - 1}
					aria-label="next page"
				>
					<KeyboardArrowRight sx={{ color: page >= Math.ceil(rowCount / rowsPerPage) - 1 ? "#313131" : "#fff"}}/>
				</IconButton>
				<IconButton
					onClick={() => handlePageChange("last")}
					disabled={page >= Math.ceil(rowCount / rowsPerPage) - 1}
					aria-label="last page"
				>
					<LastPageIcon sx={{ color: page >= Math.ceil(rowCount / rowsPerPage) - 1 ? "#313131" : "#fff"}}/>
				</IconButton>
			</Box>

			<Box sx={{
				display: "flex",
				ml: 1,
				alignItems: "center"
			}}>
				<Typography variant="caption">
					Rows:
				</Typography>
				<PageSelectButton defText="RowsPerPage" text={rowsPerPage} buttons={[25, 50, 100, "All"]} setFunc={handleRowChange}/> 
			</Box>
			
			<Box sx={{
				display: "flex",
				ml: 1,
				my: 1
			}}>
				<Typography variant="caption">
					{Math.min(page * rowsPerPage + 1, rowCount)}-{Math.min((page + 1) * rowsPerPage, rowCount)} of {rowCount}
				</Typography>
			</Box>
		</Toolbar>
	);
};

export default function MapList() 
{
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("name");

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	const [filter, setFilter] = React.useState("");

	let loading = false;
	
	const dispatch = useDispatch();
	const rows = useSelector(state =>
	{
		loading = false;

		if ( state.mapdata.maplist )
		{
			if ( state.mapdata.maplist.loading )
				loading = true;
			else
				return state.mapdata.maplist;
		}

		return [];
	});
	
	useEffect(() =>
	{
		dispatch(getMapList());
	}, []);

	const filteredRows = React.useMemo(() => 
	{
		return rows.filter((r) => 
		{
			return (filter ? r.name.includes(filter) : true);
		});
	}, [rows, filter]);

	const handleRequestSort = (event, property = "name") => 
	{
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};


	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;
	const cellColor = "rgba(255, 255, 255, 0.8)";
	const topFontWeight = "normal";

	return (
		<Box sx={{ width: "100%" }}>
			<Paper square sx={{ width: "100%", mb: 2 }}>
				<DataTableHeader
					filter={[filter, setFilter]}
					setPage={setPage}
				/>
				<TableContainer>
					<Table size="small">
						<TableHead>
							{ rows.length === 0 ? (
								<TableRow>
									<TableCell align="center">{loading ? "Loading..." : "No Maps Found"}</TableCell>
								</TableRow>
							):(
								<TableRow>
									<TableCell
										align="left"
										sx={{
											paddingLeft: 2,
											cursor: "pointer",
											"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "55%"
										}}
										onClick={(e) => handleRequestSort(e, "name")}
									>
										<Box sx={{ display: "flex", alignItems: "center" }}>
											<TerrainIcon sx={{ alignSelf: "flex-start", color: "#D3C7A3", mr: 0.2, mt: 0.1 }} fontSize="small" />
											<Typography noWrap variant="body2" sx={{ fontWeight: "bold"}} >Map Name</Typography>
											{orderBy === "name" && (order === "asc" ? <ArrowDropDownIcon/>:<ArrowDropUpIcon/>)||(<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)}
										</Box>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											paddingRight: 2,
											cursor: "pointer",
											"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "5%"
										}}
										onClick={(e) => handleRequestSort(e, "tier")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
											{
												orderBy === "tier" &&
												(order === "asc" ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>)
												|| (<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)
											}
											{/* <InsightsIcon sx={{ alignSelf: "flex-start", color: "white" }} fontSize="small" /> */}
											<Icon sx={{ alignSelf: "flex-start" }} fontSize="small">
												<TierIcon />
											</Icon>
											<Typography variant="body2" sx={{ fontWeight: topFontWeight}} >Tier</Typography>
										</Box>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											paddingRight: 2,
											cursor: "pointer",
											"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "5%"
										}}
										onClick={(e) => handleRequestSort(e, "median_time")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
											{
												orderBy === "median_time" &&
												(order === "asc" ? <ArrowDropDownIcon/>:  <ArrowDropUpIcon/>)
												|| (<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)
											}
											<SquareFootIcon sx={{ alignSelf: "flex-start", color: "gold" }} fontSize="small" />
											<Typography variant="body2" sx={{ fontWeight: topFontWeight}} >Length</Typography>
										</Box>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											paddingRight: 2,
											cursor: "pointer",
											"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "5%"
										}}
										onClick={(e) => handleRequestSort(e, "completions")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
											{
												orderBy === "completions" &&
												(order === "asc" ? <ArrowDropDownIcon/>:  <ArrowDropUpIcon/>)
												|| (<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)
											}
											<CheckIcon sx={{ color: "#00FF00", alignSelf: "flex-start"}} fontSize="small" />
											<Typography variant="body2" sx={{ fontWeight: topFontWeight}} >Clears</Typography>
										</Box>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											paddingRight: 2,
											cursor: "pointer",
											"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "10%"
										}}
										onClick={(e) => handleRequestSort(e, "playtime")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
											{
												orderBy === "playtime" &&
												(order === "asc" ? <ArrowDropDownIcon/>:  <ArrowDropUpIcon/>)
												|| (<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)
											}
											<QueryBuilderIcon sx={{ alignSelf: "flex-start" }} fontSize="small" />
											<Typography variant="body2" sx={{ fontWeight: topFontWeight}} >Played</Typography>
										</Box>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											paddingRight: 2,
											cursor: "pointer",
											"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "15%"
										}}
										onClick={(e) => handleRequestSort(e, "lastPlayed")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
											{
												orderBy === "lastPlayed" &&
												(order === "asc" ? <ArrowDropDownIcon/>:  <ArrowDropUpIcon/>)
												|| (<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)
											}
											{/* <CalendarMonthIcon sx={{ alignSelf: "flex-start", color: "#42a5f5" }} fontSize="small" /> */}
											<Icon sx={{ alignSelf: "flex-start" }} fontSize="small">
												<CalendarIcon />
											</Icon>
											<Typography noWrap variant="body2" sx={{ fontWeight: topFontWeight}} >Last Played</Typography>
										</Box>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											paddingRight: 2,
											cursor: "pointer",
											"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "10%"
										}}
										onClick={(e) => handleRequestSort(e, "added")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
											{
												orderBy === "added" &&
												(order === "asc" ? <ArrowDropDownIcon/>:  <ArrowDropUpIcon/>)
												|| (<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)
											}
											<Icon sx={{ alignSelf: "flex-start" }} fontSize="small">
												<CalendarRedIcon />
											</Icon>
											<Typography variant="body2" sx={{ fontWeight: topFontWeight}} >Added</Typography>
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableHead>
						<TableBody>
							{stableSort(filteredRows, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => (
									<TableRow
										key={index}
										sx={{ 
											height: 22,
											//position: "relative",
											"&:hover": { background: "rgba(255, 255, 255, 0.05)" },
										}}
										//sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell
											component="th"
											scope="row"
											align="left"
											padding="none"
											sx={{
												pl: 3,
												//position: "relative"
												//wordBreak: "break-all",
												//position: "relative"
											}}
										>
											<HoverLink to={`/maps/${row.name}`}>
												<Typography variant="inherit" sx={{ display: { xs: "block", md: "none" } }}>
													{row.mapNoPrefix}
												</Typography>
												<Typography variant="inherit" sx={{ display: { xs: "none", md: "block" } }}>
													{row.name}
												</Typography>
											</HoverLink>
										</TableCell>
										<TableCell align="right" sx={{ pr: 2, color: cellColor }}>
											{row.tier}
										</TableCell>
										<TableCell align="right" sx={{ pr: 2, color: cellColor }}>
											{formatSecondsShort(row.median_time)}
										</TableCell>
										<TableCell align="right" sx={{ pr: 2, color: cellColor }}>
											{row.completions || "-"}
										</TableCell>
										<TableCell align="right" sx={{ pr: 2, color: cellColor }}>
											{formatTimeWSuffix(row.playtime)}
										</TableCell>
										
										<TableCell align="right" sx={{ pr: 2, color: cellColor }}>
											<Typography variant="inherit" noWrap>
												{formatDate(row.lastPlayed)}
											</Typography>
										</TableCell>
										<TableCell align="right" sx={{ pr: 2, color: cellColor }}>
											{formatDateShort(row.added)}
										</TableCell>
									</TableRow>
								))}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 22 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<DataTableFooter
					pageA={[page, setPage]}
					rowsPerPageA={[rowsPerPage, setRowsPerPage]}
					rowCount={filteredRows.length}
				/>
			</Paper>
		</Box>
	);
}