import React from "react";

import {
	Box, Table, TableBody, TableCell, TableContainer,
	TableHead, TableRow, Button, Menu, MenuItem, TextField,
	Toolbar, Typography, Paper, IconButton, Icon
} from "@mui/material";

import { alpha, styled } from "@mui/material/styles";

import { displayOrderButtons, styles, types } from "../../utils/constants";
import { formatDateShort, formatSeconds } from "../../utils/dataFormatters";

//icons
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from "@mui/icons-material/Clear";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { ReactComponent as CalendarIcon } from "../../icons/kalenteri_red.svg";
import { getServerRecentTimes } from "../../storage/times";
import { ReactComponent as VariantIcon } from "../../icons/variantti.svg";

const HoverLink = styled(Link)(() => ({
	textDecoration: "none",
	color: "white"	
}));


function descendingComparator(a, b, orderBy) 
{
	if ( !a[orderBy] )
		return -1;

	if ( !b[orderBy] )
		return 1;

	if (b[orderBy] < a[orderBy]) 
		return -1;

	if (b[orderBy] > a[orderBy]) 
		return 1;

	return 0;
}

function ascendingComparator(a, b, orderBy) 
{
	if ( !b[orderBy] || b[orderBy] === "-" )
		return 1;

	if ( !a[orderBy] || a[orderBy] === "-" )
		return -1;

	if (b[orderBy] < a[orderBy]) 
		return 1;

	if (b[orderBy] > a[orderBy]) 
		return -1;

	return 0;
}

function getNComparator(order, orderBy) 
{
	return order === "desc"
		? (a, b) => ascendingComparator(a, b, orderBy)
		: (a, b) => -ascendingComparator(a, b, orderBy);
}


function getComparator(order, orderBy) 
{
	if ( ["date"].includes(orderBy) )
		return getNComparator(order, orderBy);

	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) 
{
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => 
	{
		const order = comparator(a[0], b[0]);
		if (order !== 0) 
		{
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const ButtonMenu = ( { defText, fields, buttons = [], ...rest } ) =>
{
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [text, setText] = fields; 

	const open = Boolean(anchorEl);

	const handleClick = (event) => 
	{
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (buttonText) => 
	{
		if ( buttonText )
			setText(buttonText);

		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				id={"table-button" + defText }
				aria-controls={open ? "table-menu" + defText : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				variant="contained"
				disableElevation
				disableRipple
				endIcon={<KeyboardArrowDownIcon sx={{ marginLeft: 0.5 }} />}
				size="small"
				{...rest}
			>
				{ text ? text : defText }
			</Button>
			<Menu
				id={ "table-menu" + defText }
				MenuListProps={{ "aria-labelledby": "table-button" + defText }}
				anchorEl={anchorEl}
				open={open}
				onClose={() => handleClose()}
				transitionDuration={0}
			>
				{buttons.map((txt, index) => (
					<MenuItem dense
						key={index}
						onClick={() => handleClose(txt)}
						disableRipple
					>
						{txt}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

const PageSelectButton = ( { defText, text, buttons = [], setFunc, ...rest } ) =>
{
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [buttonText, setButtonText] = React.useState(text);

	const open = Boolean(anchorEl);

	const handleClick = (event) => 
	{
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (buttonText) => 
	{
		if ( buttonText )
		{
			setFunc(buttonText);
			setButtonText(buttonText);
		}

		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				id={"table-button" + defText }
				aria-controls={open ? "table-menu" + defText : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				variant="text"
				disableElevation
				disableRipple
				endIcon={<ArrowDropDownIcon sx={{ color: "#1e1e1e"}}/>}
				size="small"
				sx={{ minWidth: 0, textTransform: "none" }}
				color="white"
				{...rest}
			>
				<Typography variant="caption">
					{ buttonText ? buttonText : defText }
				</Typography>

			</Button>
			<Menu
				id={ "table-menu" + defText }
				MenuListProps={{ "aria-labelledby": "table-button" + defText }}
				anchorEl={anchorEl}
				open={open}
				onClose={() => handleClose()}
				transitionDuration={0}
			>
				{buttons.map((txt, index) => (
					<MenuItem dense
						key={index}
						onClick={() => handleClose(txt)}
						disableRipple
					>
						{txt}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

const DataTableHeader = ({ variant, filter, setPage })  =>
{
	const [v, setV] = variant;
	const [f, setF] = filter;

	const variantList = displayOrderButtons.map(i => i = styles[i]).concat(types.slice(1, 3)).concat("Main Combined");

	const handleClearClick = () =>
	{
		setF("");
	};

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 2 },
				display: "flex",
				flexWrap: "wrap",
			}}
		>
			<Box sx={{
				display: "flex",
				my: 2,
				mr: 2,
				flexGrow: 1,
			}}
			>
				<ButtonMenu defText="Variant" fields={[v, setV]} buttons={variantList} />
			</Box>
			
			<Box sx={{
				display: "flex",
				my: 1
			}}>
				<TextField
					hiddenLabel
					id="filled-hidden-label-small"
					variant="outlined"
					size="small"
					label="Filter players"
					value={f}
					autoComplete="off"
					onChange={(e) =>
					{
						setF(e.target.value);
						setPage(0);
					}}
					InputProps={{
						endAdornment: (
							<IconButton
								onClick={handleClearClick}
								sx={{ visibility: f ? "visible" : "hidden"}}
							>
								<ClearIcon />
							</IconButton>

						)
					}}
				/>
			</Box>
		</Toolbar>
	);
};

const DataTableFooter = ({ pageA, rowsPerPageA, rowCount }) =>
{
	const [page, setPage] = pageA;
	const [rowsPerPage, setRowsPerPage] = rowsPerPageA;

	const handlePageChange = (buttonType) =>
	{
		switch(buttonType)
		{
			case "first":
				setPage(0);
				break;
			case "left":
				setPage(page - 1);
				break;
			case "right":
				setPage(page + 1);
				break;
			case "last":
				setPage(Math.max(0, Math.ceil(rowCount / rowsPerPage) - 1));
				break;
		}
	};

	const handleRowChange = (value) =>
	{
		if ( value === "All" )
			value = rowCount;

		setRowsPerPage(value);
		setPage(0);
	};

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 2 },
				py: 0,
				display: "flex",
				flexWrap: "wrap",
			}}
		>
			<Box sx={{
				display: "flex",
				//my: 2,
				mr: 2,
				flexGrow: 1,
			}}
			>
				<IconButton
					onClick={() => handlePageChange("first")}
					disabled={page === 0}
					aria-label="first page"
				>
					<FirstPageIcon sx={{ color: page === 0 ? "#313131" : "#fff"}}/>
				</IconButton>
				<IconButton
					onClick={() => handlePageChange("left")}
					disabled={page === 0}
					aria-label="previous page"
				>
					<KeyboardArrowLeft sx={{ color: page === 0 ? "#313131" : "#fff"}}/>
				</IconButton>
				<IconButton
					onClick={() => handlePageChange("right")}
					disabled={page >= Math.ceil(rowCount / rowsPerPage) - 1}
					aria-label="next page"
				>
					<KeyboardArrowRight sx={{ color: page >= Math.ceil(rowCount / rowsPerPage) - 1 ? "#313131" : "#fff"}}/>
				</IconButton>
				<IconButton
					onClick={() => handlePageChange("last")}
					disabled={page >= Math.ceil(rowCount / rowsPerPage) - 1}
					aria-label="last page"
				>
					<LastPageIcon sx={{ color: page >= Math.ceil(rowCount / rowsPerPage) - 1 ? "#313131" : "#fff"}}/>
				</IconButton>
			</Box>

			<Box sx={{
				display: "flex",
				ml: 1,
				alignItems: "center"
			}}>
				<Typography variant="caption">
					Rows:
				</Typography>
				<PageSelectButton defText="RowsPerPage" text={rowsPerPage} buttons={[25, 50, 100, "All"]} setFunc={handleRowChange}/> 
			</Box>
			
			<Box sx={{
				display: "flex",
				ml: 1,
				my: 1
			}}>
				<Typography variant="caption">
					{Math.min(page * rowsPerPage + 1, rowCount)}-{Math.min((page + 1) * rowsPerPage, rowCount)} of {rowCount}
				</Typography>
			</Box>
		</Toolbar>
	);
};

const inputToVariant = (input) =>
{
	if ( !input )
		return null;

	if ( input === "Bonus" )
		return "bonus";
	else if ( input === "Challenge" )
		return "extra";
	else if ( input === "Main Combined" )
		return "all";
	else
	{
		for ( let i = 0; i < styles.length; i++ )
		{
			if ( input === styles[i] )
				return String(i);
		}
	}

	return null;
};

export default function TimeList({ map }) 
{
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("time");

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	const [filter, setFilter] = React.useState("");

	const [searchParams, setSearchParams] = useSearchParams();

	let loading = false;

	const variant = searchParams.get("v") || "";
	const setVariant = (value) =>
	{
		searchParams.set("v", value);
		setSearchParams(searchParams);
		setOrderBy("time");
		setPage(0);
	};

	const rows = useSelector(state =>
	{
		loading = false;
		const v = inputToVariant(variant);

		if ( !v )
			return [];

		const index = `times:${map}:${v}:`;

		if ( state.times[index] )
		{
			if ( state.times[index].loading )
				loading = true;
			else
				return state.times[index];
		}

		return [];
	});
	const dispatch = useDispatch();
	
	React.useEffect(() =>
	{
		if ( variant )
		{
			const v = inputToVariant(variant);

			if ( !v )
				return;

			const index = `times:${map}:${v}:`;

			dispatch(getServerRecentTimes(index, { variant: v, map: map }));

		}
	}, [variant]);


	const filteredRows = React.useMemo(() => 
	{
		return rows.filter((r) => 
		{
			return (filter ? r.name.toLowerCase().includes(filter.toLowerCase()) : true);
		});
	}, [rows, filter]);

	const handleRequestSort = (event, property = "map") => 
	{
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;
	const cellColor = "rgba(255, 255, 255, 0.8)";

	const getEmptyListText = () =>
	{
		if ( variant )
			return loading ? "Loading..." : "No Times Found";

		return "No Times Loaded";
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper square sx={{ width: "100%", mb: 2 }}>
				<DataTableHeader
					variant={[variant, setVariant]}
					filter={[filter, setFilter]}
					setPage={setPage}
				/>
				<TableContainer>
					<Table size="small">
						<TableHead>
							{ rows.length === 0 ? (
								<TableRow>
									<TableCell align="center">{getEmptyListText()}</TableCell>
								</TableRow>
							):(
								<TableRow>
									<TableCell
										align="left"
										sx={{
											pl: 2,
											//cursor: "pointer",
											//"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "35%"
										}}
										//onClick={(e) => handleRequestSort(e, "name")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
											<PersonIcon sx={{ alignSelf: "flex-start", mb: 0.5, color: "#ff7500" }} fontSize="small" />
											<Typography variant="body2" sx={{ fontWeight: "bold" }}>Name</Typography>
										</Box>
									</TableCell>
									{ variant === "Main Combined" && (
										<TableCell
											align="right"
											sx={{
												pr: 2,
												cursor: "pointer",
												"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
												userSelect: "none",
												width: "15%"
											}}
											onClick={(e) => handleRequestSort(e, "variantValue")}
										>
											<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
												{
													orderBy === "variantValue" &&
													(order === "asc" ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>)
													|| (<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)
												}
												<Icon sx={{ alignSelf: "flex-start" }} fontSize="small">
													<VariantIcon />
												</Icon>
												<Typography variant="body2" >Variant</Typography>
											</Box>
										</TableCell>
									)}
									<TableCell
										align="right"
										sx={{
											pr: 2,
											cursor: "pointer",
											"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "15%"
										}}
										onClick={(e) => handleRequestSort(e, "position")}
									>
										<Box sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "flex-end"
										}}>
											{
												orderBy === "position" &&
													(order === "asc" ? <ArrowDropDownIcon/>:  <ArrowDropUpIcon/>)
													|| (<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)
											}
											<Typography variant="body2">#</Typography>
										</Box>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											pr: 2,
											cursor: "pointer",
											"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "10%"
										}}
										onClick={(e) => handleRequestSort(e, "time")}
									>
										<Box sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "flex-end"
										}}>
											{
												orderBy === "time" &&
													(order === "asc" ? <ArrowDropDownIcon/>:  <ArrowDropUpIcon/>)
													|| (<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)
											}
											<TimerOutlinedIcon sx={{ alignSelf: "flex-start", color: "white" }} fontSize="small" />
											<Typography noWrap variant="body2">Time</Typography>
										</Box>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											pr: 2,
											cursor: "pointer",
											"&:hover": {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)},
											userSelect: "none",
											width: "25%"
										}}
										onClick={(e) => handleRequestSort(e, "date")}
									>
										<Box sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "flex-end"
										}}>
											{
												orderBy === "date" &&
													(order === "asc" ? <ArrowDropDownIcon/>:  <ArrowDropUpIcon/>)
													|| (<ArrowDropDownIcon sx={{ visibility: "hidden" }}/>)
											}
											<Icon sx={{ alignSelf: "flex-start" }} fontSize="small">
												<CalendarIcon />
											</Icon>
											<Typography variant="body2">Date</Typography>
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableHead>
						<TableBody>
							{stableSort(filteredRows, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => (
									<TableRow
										key={index}
										sx={{
											height: 22,
											"&:hover": { background: "rgba(255, 255, 255, 0.05)" },
										}}
										//sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell
											component="th"
											scope="row"
											align="left"
											padding="none"
											sx={{
												pl: 3,
												//position: "relative"
												//wordBreak: "break-all",
											}}
										>
											<HoverLink to={`/players/${row.id}`}>
												<Typography variant="inherit" noWrap>
													{row.name}
												</Typography>
											</HoverLink>
										</TableCell>
										{ variant === "Main Combined" && (
											<TableCell align="right" sx={{ pr: 2, color: cellColor }}>
												<Typography noWrap variant="inherit">
													{row.variant}
												</Typography>
											</TableCell>
										)}
										<TableCell align="right" sx={{ pr: 2, color: cellColor }}>
											{row.position}
										</TableCell>
										<TableCell align="right" sx={{ pr: 2, color: cellColor }}>
											<Typography variant="inherit" noWrap>
												{row.time ? variant === "Challenge" ? "-" : formatSeconds(row.time) : "-"}
											</Typography>
										</TableCell>
										<TableCell align="right" sx={{ pr: 2, color: cellColor }}>
											{row.time ? formatDateShort(row.date) : "-"}
										</TableCell>
									</TableRow>
								))}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 22 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<DataTableFooter
					pageA={[page, setPage]}
					rowsPerPageA={[rowsPerPage, setRowsPerPage]}
					rowCount={filteredRows.length}
				/>
			</Paper>
		</Box>
	);
}