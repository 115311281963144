import React from "react";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import PlayerList from "../components/PlayerList";

const PlayersPage = () =>
{
	return (
		<Box p={0}>
			<Typography 
				variant="h6"
				color="primary"
			> 
				TOP 100 PLAYERS
			</Typography>
			<Grid container spacing={2} justifyContent="center">
				<Grid xs={12}>
					<PlayerList />
				</Grid>
			</Grid>
		</Box>
	);
};

export default PlayersPage;