import { createSlice } from "@reduxjs/toolkit";
import searchService from "../services/search";

const searchSlice = createSlice({
	name: "search",
	initialState: {},
	reducers:
	{
		addDataToIndex(state, action)
		{
			const index = action.payload.index;
			const data = action.payload.data;

			const curState = state;
			curState[index] = data;

			return curState;
		},
		deleteDataFromIndex(state, action)
		{
			const index = action.payload.index;

			const curState = state;
			delete curState[index];

			return curState;
		}
	}
});

export const { 
	addDataToIndex,
	deleteDataFromIndex
} = searchSlice.actions;

export default searchSlice.reducer;

export const getSearchResults = (index, query) =>
{
	return async (dispatch, getState) =>
	{
		if ( getState().search[index] )
			return;

		try
		{
			dispatch(addDataToIndex({ index, data: { loading: true } }));

			if ( query.length < 2 )
			{
				dispatch(addDataToIndex({ index, data: { names: [], ids: [], maps: [] } }));
				return;
			}

			const data = await searchService.getSearchResults(query);
			
			dispatch(addDataToIndex({ index, data }));
		}
		catch ( e )
		{
			dispatch(deleteDataFromIndex({ index }));
			//console.log("error", e);
		}
	};
};