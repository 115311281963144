import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { Paper, InputBase, IconButton, Box } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import SearchResults from "./SearchResults";
import { getSearchResults } from "../../storage/search";

const SearchBar = () =>
{
	const [value, setValue] = React.useState("");
	const [delayValue] = useDebounce(value, 500);
	const [data, setData] = useState(null);
	const searchInput = useRef(null);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [focus, setFocus] = useState(false);

	//let loading = true;
	const searches = useSelector(state => state.search);
	const [loading, setLoading] = useState(null);

	React.useEffect( () =>
	{
		//console.log("D:", delayValue, value);
		if ( delayValue )
		{
			//console.log("BBBBBBBBBB", Date.now());
			const index = `search:${delayValue}`;

			dispatch(getSearchResults(index, delayValue));
		}
	}, [delayValue]);


	React.useEffect( () =>
	{
		if ( loading === null && value === "" )
		{
			setLoading(true);
		}
		else
		{
			let load = true;

			if ( value === delayValue || value === "" )
				load = false;

			const index = `search:${value}`;

			//console.log(value, delayValue, index, searches[index], Date.now());
		
			if ( searches[index] )
			{
				if ( searches[index].loading )
				{
					load = true;
				}
				else
				{
					load = false;
				
					if (searches[index] !== data )
						setData(searches[index]);
				}
			}

			if ( loading !== load )
				setLoading(load);
			
			//console.log(data, load, loading, searches[index]);
		}


	}, [searches, value]);
	
	const handleSubmit = (event) =>
	{
		event.preventDefault();
		
		//console.log("S:", delayValue, value);

		if ( value.length > 0 )
		{
			searchInput.current.blur();
			navigate({
				pathname: "/search",
				search: `?q=${value}`,
			});
		}
	};
	
	const handleChange = (event) =>
	{
		setValue(event.target.value);
		//setLoading(true);
		//console.log("C:", delayValue, value);
	};

	const handleClick = () =>
	{
		setValue("");
		searchInput.current.blur();
	};

	const onInputClick = () =>
	{
		if ( !focus )
			setFocus(true);
	};

	const onInputKeyDown = (event) =>
	{
		if ( event.key === "Escape" )
		{
			if ( focus )
				setFocus(false);
			else
				searchInput.current.blur();
		}
	};
	
	return (
		<Box 
			sx={{
				display: "flex",
				alignItems: "center",
				height: "100%",
				width: 300,
				flexDirection: "column",
				mt: "12px"
			}}
		>
			<Paper
				elevation={0}
				component="form"
				sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100%", height: "75%" }}
				onSubmit={handleSubmit}
			>
				<InputBase
					inputRef={searchInput} 
					autoComplete="off"
					sx={{ ml: 1, flex: 1 }}
					placeholder="Search name, id, map"
					inputProps={{ "aria-label": "search" }}
					value={value}
					onChange={handleChange}
					onFocus={()=>setFocus(true)}
					onBlur={()=>setFocus(false)}
					onClick={onInputClick}
					onKeyDown={onInputKeyDown}
				/>
				<IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={handleSubmit}>
					<SearchIcon color="white" />
				</IconButton>
			</Paper>
			{ focus && value.length > 0 && (
				<SearchResults
					data={data || { names: null, ids: null, maps: null }}
					loading={loading}
					click={handleClick}
				/>
			)}
		</Box>
	);
};

export default SearchBar;