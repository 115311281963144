import { createTheme } from "@mui/material";

const theme = createTheme({
	palette:
	{
		mode: "light",
		primary:
		{
			main: "#fb8c00",
		},
		secondary:
		{
			main: "#ef6c00",
		},
		background:
		{
			default: "#303030",
			paper: "#424242",
		},
		white:
		{
			main: "#fff"
		},
		divider: "rgba(255, 255, 255, 0.12)",
		text:
		{
			primary: "#fff",
			secondary: "rgba(255, 255, 255, 0.7)",
			disabled: "rgba(255, 255, 255, 0.5)",
			hint: "rgba(255, 255, 255, 0.5)",
		},
	},
	components:
	{
		MuiAppBar:
		{
			styleOverrides:
			{
				colorPrimary: 
				{
					backgroundColor: "#212121"
				}
			}
			
			
		},
		MuiToolbar:
		{
			styleOverrides:
			{
				dense:
				{
					height: 48,
					minHeight: 48
				}
			}
		},
		MuiTableContainer:
		{
			styleOverrides:
			{
				root:
				{
					//maxWidth: 500
				}
			}
		},
		MuiTableCell:
		{
			styleOverrides:
			{
				root:
				{
					fontSize: 13,
					borderColor: "rgba(255, 255, 255, 0.12)",
					paddingLeft: 3,
					paddingRight: 0,
					paddingTop: 1,
					paddingBottom: 1,
				}
			}
		},
		MuiTabPanel:
		{
			styleOverrides:
			{
				root:
				{
					color: "white",
					background: "#424242",
				}
			}
		},
		MuiButton:
		{
			styleOverrides:
			{
				endIcon:
				{
					marginLeft: 0
				}
			}
		}
	},
});

export default theme;