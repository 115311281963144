import { createSlice } from "@reduxjs/toolkit";
import timesService from "../services/times";
import { displayOrder, styles } from "../utils/constants";
import { stripPrefix } from "../utils/dataFormatters";

const timesSlice = createSlice({
	name: "times",
	initialState: {},
	reducers:
	{
		addDataToIndex(state, action)
		{
			const index = action.payload.index;
			const data = action.payload.data;

			const curState = state;
			curState[index] = data;

			return curState;
		},
		deleteDataFromIndex(state, action)
		{
			const index = action.payload.index;

			const curState = state;
			delete curState[index];

			return curState;
		}
	}
});

export const { 
	addDataToIndex,
	deleteDataFromIndex
} = timesSlice.actions;

export default timesSlice.reducer;

export const getServerRecentTimes = (index, params) =>
{
	return async (dispatch, getState) =>
	{
		if ( getState().times[index] )
			return;

		try
		{
			dispatch(addDataToIndex({ index, data: { loading: true } }));

			const data = await timesService.getTimesWithParams(params);

			data.map(row =>
			{
				if ( row.map )
					row.mapNoPrefix = stripPrefix(row.map);

				if ( row.type === 1 )
				{
					row.variantValue = 101;
					row.variant = "Bonus";
				}
				else if ( row.type === 2 )
				{
					row.variantValue = 201;
					row.variant = "Challenge";
					row.position = 1;
				}
				else
				{
					row.variantValue = displayOrder[row.style];	
					row.variant = styles[row.style];
				}

				if ( row.name === null )
					row.name = "Unknown";
			});
			
			dispatch(addDataToIndex({ index, data }));
		}
		catch ( e )
		{
			dispatch(deleteDataFromIndex({ index }));
			//console.log("error", e);
		}
	};
};

export const getServerPotato = () =>
{
	return async (dispatch, getState) =>
	{
		if ( getState().times.potato )
			return;

		try
		{
			dispatch(addDataToIndex({ index: "potato", data: { loading: true } }));

			const data = await timesService.getPotato();

			data[3].sub.map(row =>
			{
				if ( row.type === 1 )
				{
					row.variantValue = 101;
					row.variant = "Bonus";
				}
				else if ( row.type === 2 )
				{
					row.variantValue = 201;
					row.variant = "Challenge";
				}
				else
				{
					row.variantValue = displayOrder[row.style];
					row.variant = styles[row.style];
				}
			});
			
			dispatch(addDataToIndex({ index: "potato", data }));
		}
		catch ( e )
		{
			dispatch(deleteDataFromIndex({ index: "potato" }));
			//console.log("error", e);
		}
	};
};