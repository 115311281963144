export const types =
[
	"Normal",
	"Bonus",
	"Challenge"
];

export const styles =
[
	"Normal",
	"Sideways",
	"Half-Sideways",
	"W-Only",
	"D/A-Only",
	"Segmented",
	"Surf HSW",
	"Scroll",
	"Low Gravity",
	"Segmented LG",
	"Tool-Assisted",
	"Tool-Assisted LG",
	"abc123",
	"Auto-Strafe",
	"Stamina",
];

export const stylesShort =
[
	"N",
	"SW",
	"HSW",
	"W",
	"D/A",
	"Sr",
	"SHSW",
	"Sc",
	"LG",
	"SrLG",
	"TAS",
	"TAS LG",
	"123",
	"AS",
	"Stam",
];

//we can probably skip hidden styles from this list to make it work
export const displayOrder =
[
	0, 1, 2, 3, 8, 5, 9, 7, 4, 6, 10, 11, 12, 13, 14,
];

export const displayOrderButtons =
[
	0, 1, 2, 3, 8, 5, 9, 7, 4, 6, 10, 11, 13, 14,
];

export const hiddenStyles =
[
	12,
];

export default { types, styles, stylesShort, displayOrder, displayOrderButtons, hiddenStyles };

export const backend_url = "/23697845/api/";
//export const backend_url = "http://localhost:3001/api/"; //for local use