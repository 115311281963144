import React from "react";
import { styled } from "@mui/material/styles";

const Span = styled("span")(() => ({
	//fontWeight: "bold",
	color: "#ffd100"
}));

const HighlightedResult = ({ data: { item, matches: [match] }, index }) =>
{
	if ( match.key !== index )
		return item[index];

	let p = 0;

	return (
		<span>
			{
				match.indices.map(([start, end], i) => 
				{
					const ret = (
						<React.Fragment key={i}>
							{match.value.slice(p, start)}
							<Span>
								{match.value.slice(start, end+1)}
							</Span>
						</React.Fragment>
					);
					p = end+1;
					return ret;
				})
			}
			{match.value.slice(p, match.value.length)}
		</span>
	);
};

export default HighlightedResult;