import { Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import SearchResultsFull from "../components/search/searchResultsFull";
import { getSearchResults } from "../storage/search";

const SearchPage = () =>
{
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();

	const query = searchParams.get("q");

	//console.log("SQ:", query);
	let loading = false;

	const data = useSelector(state =>
	{
		loading = false;
	
		const index = `search:${query}`;
	
		if ( state.search[index] )
		{
			if ( state.search[index].loading )
				loading = true;
			else
				return state.search[index];
		}
	
		return { names: [], ids: [], maps: [] };
	});
		
	React.useEffect(() =>
	{
		if ( query )
		{
			const index = `search:${query}`;

			dispatch(getSearchResults(index, query));
		}
	}, [query]);

	return (
		<div>
			<Typography variant="h6" color="primary">Search results for: {query}</Typography>
			<SearchResultsFull
				data={data || { names: null, ids: null, maps: null }}
				loading={loading}
			/>
		</div>
	);
};

export default SearchPage;