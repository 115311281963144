import axios from "axios";
import { backend_url } from "../utils/constants";
const baseUrl = backend_url + "players";

const getSinglePlayerStatsFull = async(id) =>
{
	const response = await axios.get(baseUrl + "/" + id + "?type=full");

	return response.data;
};

const getTopPlayersBy = async (p) =>
{
	const params = new URLSearchParams();

	if ( p )
	{
		if ( p.by ) params.append("by", p.by);
		if ( p.variant ) params.append("var", p.variant);
	}
	
	const response = await axios.get(baseUrl, { params });

	return response.data;
};

const getOldestTimeAndRecord = async(id) =>
{
	const response = await axios.get(baseUrl + "/" + id + "?type=oldest");

	return response.data;
};

const getMaps = async (id, p) =>
{
	const params = new URLSearchParams();
	
	if ( p )
	{
		if ( p.type ) params.append("type", p.type);
		if ( p.variant ) params.append("var", p.variant);
	}

	const response = await axios.get(baseUrl + "/" + id, { params });

	return response.data;
};

const exports =
{
	getSinglePlayerStatsFull,
	getTopPlayersBy,
	getOldestTimeAndRecord,
	getMaps,
};

export default exports;