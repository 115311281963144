import React from "react";
import { Paper, Typography, Box } from "@mui/material";

import { styles } from "../../utils/constants";
import { formatDateShort } from "../../utils/dataFormatters";
import { useSelector } from "react-redux";

const Stats = ({stats}) =>
{
	const oldest = useSelector(state => state.players[stats.id].oldest || {});

	const playPerc = stats.playtime / stats.servertime;
	const ratioString = playPerc ? `1 / ${ (1 / playPerc).toFixed(1)}` : "0 / 1";

	const getOldestTime = () =>
	{
		if ( !oldest.time )
			return "none";

		return (
			<>
				<Typography variant="body2">{oldest.time.map}</Typography>
				<Typography variant="body2">{styles[oldest.time.style]}</Typography>
				<Typography variant="body2">{formatDateShort(oldest.time.date)}</Typography>
			</>
		);
	};

	const getOldestRecord = () =>
	{
		if ( !oldest.record )
			return "none";

		const days = Math.ceil((new Date().getTime() - oldest.record.date * 1000) / (1000 * 3600 * 24));

		return (
			<>
				<Typography variant="body2">{oldest.record.map}</Typography>
				<Typography variant="body2">{styles[oldest.record.style]}</Typography>
				<Typography variant="body2">{formatDateShort(oldest.record.date)}</Typography>
				<Typography variant="body2">{days} days</Typography>
			</>
		);
	};

	return (
		<Paper square elevation={0} sx={{ 
			flexGrow: 1,
			display: "flex",
			flexWrap: "wrap",
			flexDirection: "column",
			gap: "2px",
			padding: "2px",
			background: "#212121"
		}}>

			<Box sx={{ background: "#424242", display: "flex", px: 1, columnGap: 1, alignItems: "flex-end", justifyContent: "space-between", py: 0.5 }}>
				<Typography color="primary" variant="body2">
					Rank
				</Typography>
				<Typography variant="body2">
					{`${stats.rank} - (${(stats.rank * 100 / stats.totalRanks).toFixed(3)}%)`}
				</Typography>
			</Box>
			<Box sx={{ background: "#424242", display: "flex", px: 1, columnGap: 1, alignItems: "flex-end", justifyContent: "space-between", py: 0.5 }}>
				<Typography color="primary" variant="body2">
					Total points
				</Typography>
				<Typography variant="body2">
					{stats.points.toFixed(0)} ({(stats.runs[12].pts + stats.runs[13].pts).toFixed(0)} uncounted)
				</Typography>
			</Box>
			<Box sx={{ background: "#424242", display: "flex", px: 1, columnGap: 1, alignItems: "flex-end", justifyContent: "space-between", py: 0.5 }}>
				<Typography color="primary" variant="body2">
					Play to spec ratio
				</Typography>
				<Typography variant="body2">
					{ratioString} ({(playPerc * 100).toFixed(0)}%)
				</Typography>
			</Box>
			<Box sx={{ background: "#424242", display: "flex", px: 1, columnGap: 1, justifyContent: "space-between", py: 0.5 }}>
				<Typography color="primary" variant="body2">
					Oldest time
				</Typography>
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
					alignItems: "flex-end",
					flexGrow: 1,
				}}>
					{getOldestTime()}
				</Box>
			</Box>
			<Box sx={{ background: "#424242", display: "flex", px: 1, columnGap: 1, justifyContent: "space-between", py: 0.5 }}>
				<Typography color="primary" variant="body2" noWrap>
					Longest held record
				</Typography>
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
					alignItems: "flex-end",
					flexGrow: 1,
				}}>
					{getOldestRecord()}
				</Box>
			</Box>
		</Paper>
	);
};

export default Stats;