import { createSlice } from "@reduxjs/toolkit";
import playersService from "../services/players";
import { displayOrder, hiddenStyles, styles, types } from "../utils/constants";
import { stripPrefix } from "../utils/dataFormatters";

const playersSlice = createSlice({
	name: "players",
	initialState: {},
	reducers:
	{
		addPlayer(state, action)
		{
			const player = action.payload.player;
			const id = action.payload.id;
			const players = state;
			players[id] = player;

			return players;
		},
		addOldestTimesToPlayer(state, action)
		{
			const oldest = action.payload.oldest;
			const id = action.payload.id;
			const players = state;
			players[id].oldest = oldest;

			return players;
		},
		addDataToIndex(state, action)
		{
			const index = action.payload.index;
			const data = action.payload.data;

			const players = state;
			players[index] = data;

			return players;
		},
		deleteDataFromIndex(state, action)
		{
			const index = action.payload.index;

			const curState = state;
			delete curState[index];

			return curState;
		}
	}
});

export const { 
	addPlayer,
	addOldestTimesToPlayer,
	addDataToIndex,
	deleteDataFromIndex
} = playersSlice.actions;

export default playersSlice.reducer;

export const getPlayerFull = (id) =>
{
	return async (dispatch, getState) =>
	{
		if ( getState().players[id] )
			return;

		try
		{
			const player = await playersService.getSinglePlayerStatsFull(id);

			//reorder styles and add display name
			const len = player.runs.length;
			const temp = Array(len).fill({});
	
			for ( let i = 0; i < len; i++ )
			{
				const type = player.runs[i].type;
				const style = player.runs[i].style;
				const index = type > 0 ? len - type % 2 - 1 : displayOrder[style];
	
				temp[index] = player.runs[i];
				temp[index].name = type > 0 ? types[type] : styles[style];
			}

			hiddenStyles.map(style => temp.splice(style, 1));
			
	
			player.runs = temp;
	
			dispatch(addPlayer( { player, id }));

			const oldest = await playersService.getOldestTimeAndRecord(id);

			dispatch(addOldestTimesToPlayer( { oldest, id }));
		}
		catch ( e )
		{
			try
			{
				if ( e.response.status === 404 )
					dispatch(addPlayer( { player: { error: true, msg: "not found"}, id }));
				else
					dispatch(addPlayer( { player: { error: true, msg: "error"}, id }));
			}
			catch ( e2 )
			{
				dispatch(addPlayer( { player: { error: true, msg: "error"}, id }));
			}
		}
	};
};

export const getPlayerMapInfo = (id, params, index) =>
{
	return async (dispatch, getState) =>
	{
		if ( getState().players[index] )
			return;

		try
		{
			dispatch(addDataToIndex({ index, data: { loading: true } }));

			const data = await playersService.getMaps(id, params);

			data.map(row =>
			{
				row.mapNoPrefix = stripPrefix(row.map);

				if ( row.name === null )
					row.name = "";
			});
			
			dispatch(addDataToIndex({ index, data }));
		}
		catch ( e )
		{
			dispatch(deleteDataFromIndex({ index }));
			//console.log("error", e);
		}
	};
};

export const getTopPlayersBy = (index, by) =>
{
	return async (dispatch, getState) =>
	{
		if ( getState().players[index] )
			return;

		try
		{
			dispatch(addDataToIndex({ index, data: { loading: true } }));

			const data = await playersService.getTopPlayersBy(by);
			dispatch(addDataToIndex({ index, data }));
		}
		catch ( e )
		{
			dispatch(deleteDataFromIndex({ index }));
			//console.log("error", e);
		}
	};
};