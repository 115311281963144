import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Icon } from "@mui/material";
import NumbersIcon from "@mui/icons-material/Numbers";
import { ReactComponent as VariantIcon } from "../../icons/variantti.svg";
import { ReactComponent as PointsIcon } from "../../icons/pisteet.svg";

const Ranks = ({runs}) =>
{
	const style = { paddingLeft: 1, color: "rgba(255, 255, 255, 0.8)" };

	return (
		<TableContainer component={Paper} square >
			<Table size="small" padding="none">
				<TableHead>
					{ runs.length === 0 ? (
						<TableRow>
							<TableCell align="center">No Times Found</TableCell>
						</TableRow>
					):(
						<TableRow>
							<TableCell align="left" sx={{ paddingLeft: 1}}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
									<Typography variant="inherit">Variant</Typography>
									<Icon sx={{ alignSelf: "flex-start" }} fontSize="small">
										<VariantIcon />
									</Icon>
								</Box>
							</TableCell>
							<TableCell align="left" sx={{ paddingLeft: 1}}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
									<Typography variant="inherit">Rank</Typography>
									<NumbersIcon sx={{ color: "#ff7dde", alignSelf: "flex-start"}} fontSize="small" />
								</Box>
							</TableCell>
							<TableCell align="left" sx={{ paddingLeft: 1}}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
									<Typography variant="inherit">Points</Typography>
									<Icon sx={{ alignSelf: "flex-start" }} fontSize="small">
										<PointsIcon />
									</Icon>
								</Box>
							</TableCell>
						</TableRow>
					)}
				</TableHead>
				<TableBody>
					{runs.map((row, index) => (
						<TableRow
							key={index}
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
						>
							<TableCell component="th" scope="row" align="left" sx={style}>
								{row.name}
							</TableCell>
							<TableCell align="left" sx={style}>
								{`${row.rank} / ${row.players}`}
							</TableCell>
							<TableCell align="left" sx={style}>
								{row.pts.toFixed(2)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default Ranks;