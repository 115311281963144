import { configureStore } from "@reduxjs/toolkit";

import playersReducer from "./players";
import mapdataReducer from "./mapdata";
import timesReducer from "./times";
import searchReducer from "./search";

const store = configureStore({
	reducer:
	{
		players: playersReducer,
		mapdata: mapdataReducer,
		times: timesReducer,
		search: searchReducer
	}
});

export default store;