import axios from "axios";
import { backend_url } from "../utils/constants";
const baseUrl = backend_url + "search";

const getSearchResults = async(query) =>
{
	const params = new URLSearchParams({ q: query });

	const response = await axios.get(baseUrl, { params });

	return response.data;
};

const exports =
{
	getSearchResults,
};

export default exports;