import axios from "axios";
import { backend_url } from "../utils/constants";
const baseUrl = backend_url + "maps";

const getSingleStats = async (name) =>
{
	const params = new URLSearchParams([["data", "full"]]);

	const response = await axios.get(`${baseUrl}/${name}`, { params });

	return response.data;
};

const getAllMaps = async () =>
{
	const params = new URLSearchParams([["data", "full"]]);
	const response = await axios.get(baseUrl, { params });

	return response.data;
};

const exports =
{
	getSingleStats,
	getAllMaps,
};

export default exports;