import React from "react";
import MapList from "../components/MapList";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const MapsPage = () =>
{
	return (
		<Box p={0}>
			<Typography 
				variant="h6"
				color="primary"
			> 
				MAPS
			</Typography>
			<Grid container spacing={2} justifyContent="center">
				<Grid xs={12}>
					<MapList />
				</Grid>
			</Grid>
		</Box>
	);
};

export default MapsPage;